/* Generated by Glyphter (http://www.glyphter.com) on  Wed Mar 17 2021*/
@font-face {
    font-family: 'bp';
    src: url('../fonts/bp.eot');
    src: url('../fonts/bp.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bp.woff') format('woff'),
         url('../fonts/bp.ttf') format('truetype'),
         url('../fonts/bp.svg#bp') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='bp-icon-']:before{
	display: inline-block;
    vertical-align: middle;
   font-family: 'bp';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.bp-icon-youtube::before{content:'\0041'; color: #212121;}
p.bp-icon-youtube:hover::before{color:#FF0000;}
.bp-icon-hudl:before{content:'\0042';}
p.bp-icon-hudl:hover::before{color:#ff6300;}